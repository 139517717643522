.oss {
    display: flex;
    flex-direction: column;
    align-items: center;
    // width: 48%;
    width: 440px;
    height: 239px;
    background: #F7FAFF;
    border: 1px solid rgba(204, 223, 255, 1);
    border-radius: 8px;
    padding: 30px 0;

    .xuanZe {
        height: 100px;
        width: 100px;
        // background-color: red;
        background-size: cover;

        input {
            width: 100%;
            height: 100%;
            opacity: 0;
        }

        p {
            font-family: PingFangSC-Regular;
            font-size: 18px;
            color: #2078FF;
            letter-spacing: 0;
            text-align: center;
            font-weight: 400;
        }
    }

    button {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #2078FF;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        border: none;
        margin: 0;
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #2078FF;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        margin-top: 10px;
        background: none;
    }

    .ant-progress-line {
        width: 80% !important;
    }
}
.ming {
    cursor: pointer;
    width: 425px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
}