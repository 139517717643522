.copyright {
    width: 40%;
    background-color: #F6F9FF;
    padding: 50px 5%;
    margin: 0 auto;

    h1 {
        text-align: center;
        font-size: 50px;
    }

    h2{
        font-size: 1.4rem;
    }

    h3 {
        // font-size: 30px;
        font-size: 1.3rem;
        p {
            margin: 8px 0;
            // font-size: 26px;
            font-size: 1.2rem;
        }
        .p1{
            // font-size: 24px;
            font-size: 1.15rem;
            display: block;
            width: 100%;
        }
        .p3{
            // font-size: 18px;
            font-size: 1.1rem;
        }
    }

    div {
        padding-top: 20px;
        width: 144px;
        margin: 0 auto;
        button{
            width: 142px;
            height: 48px;
            background: #FFFFFF;
            border-radius: 27.5px;
            font-family: PingFangSC-Semibold;
            font-size: 18px;
            color: #1756BC;
            letter-spacing: 0.66px;
            font-weight: 600;
            text-align: center;
        }
    }
}