.applyAdd {
    header {
        width: 100%;
        font-family: PingFangSC-Medium;
        font-size: 24px;
        color: #3E454D;
        letter-spacing: 0;
        text-align: justify;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;

        p {
            width: 4px;
            margin-right: 10px;
            background-image: linear-gradient(180deg, #19A3F6 0%, #2077FF 84%);
            border-radius: 2px;
        }
    }

    .ant-form-item-control-input-content {
        display: flex;
        justify-content: space-between;
    }

    .video {
        width: 440px;
        height: 239px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        span {
            width: 90% !important;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
            white-space: nowrap;
        }
    }
    .video:nth-child(1){
        margin-right: 80px;
    }

    .fujian {
        display: flex;
        justify-content: flex-end;
        flex-direction: column-reverse;
        align-items: center;
        font-size: 18px;

        p {
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: space-between;

            span {
                a {
                    margin-right: 10px;
                }
            }
        }
    }

    .ant-select-selector {
        height: 58px !important;
        background: #F6F9FF;
        border-radius: 8px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #666666;
        font-weight: 400;

        .ant-select-selection-item {
            text-align: initial;
        }
    }

    .ant-form-item-label {
        font-family: PingFangSC-Regular;
        font-size: 18px !important;
        color: #3E454D;
        letter-spacing: 0;
        text-align: right;
        font-weight: 400;
        height: 58px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .ant-form-item .ant-form-item-label>label {
        font-family: PingFangSC-Regular;
        font-size: 18px !important;
        color: #3E454D;
        letter-spacing: 0;
    }

    .ant-input {
        height: 58px !important;
        font-family: PingFangSC-Regular;
        font-size: 18px !important;
        color: #999999;
        font-weight: 400;
    }

    .ant-select-single{
        height: 58px !important;
    }
    .ant-form-item-control{
        justify-content: center !important;
    }
    .ant-form-item {
        max-width: none !important;
    }
    .queDing {
        width: 120px !important;
        height: 48px;
        background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
        border-radius: 27.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PingFangSC-Semibold;
        font-size: 18px;
        color: #FFFFFF;
        letter-spacing: 0.66px;
        font-weight: 600;
        margin-bottom: 20px;
    }
    .ant-select-selector{
        height: 58px !important;
    }
    .ant-select-selection-item{
        text-align: left;
        line-height: 58px !important;
        font-size: 17px;
    }
    .ant-select-selection-placeholder{
        height: 58px !important;
        line-height: 58px !important;
        font-size: 18px;
    }
}