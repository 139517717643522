.ant-form-item-control-input-content {
    display: flex;
    justify-content: right;

    Button {
        margin-left: 20px;
    }
}

.ant-menu {
    width: 300px;
    background: #1C50A1;
    padding: 41px 32px 0 32px;
}

.menu .ant-spin-nested-loading .ant-menu .ant-menu-submenu-selected .ant-menu-submenu-title {
    height: 58px;
    background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
    border-radius: 8px;
    color: white;
}

.menu .ant-spin-nested-loading .ant-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    width: 235.89px;
}

.layout {
    width: 100%;
    height: 100%;

    p {
        margin: 0;
    }

    .header {
        padding: 0 25px;
        height: 86px;
        background-color: #1756BC;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .headerImg {
            width: 100px;
            height: 62px;
            display: flex;
            align-items: center;

            img {
                width: 152.29px;
                height: 50px;
            }

            div {
                flex-wrap: wrap;
                color: white;

                p {
                    font-size: 30px;
                }

                span {
                    white-space: nowrap;
                    font-size: 12px;
                }
            }
        }

        .tuiChu {
            width: 120px;
            height: 48px;
            background: #FFFFFF;
            border-radius: 27.5px;
            font-family: PingFangSC-Semibold;
            font-size: 18px;
            color: #1756BC;
            letter-spacing: 0.66px;
            font-weight: 600;
            text-align: center;
            line-height: 48px;
        }
    }

    .ant-layout {
        height: 100%;
        padding: 41px 160px;

        .ant-menu {
            background: #FFFFFF !important;
            border-radius: 16px !important;
            font-family: Source Han Sans;
            font-size: 16px;
            font-weight: normal;
            line-height: 19px;
            text-align: justify;
            letter-spacing: 0px;
            color: #8799B0;
        }

        .ant-menu-item{
            font-family: Source Han Sans;
            font-size: 16px;
            font-weight: normal;
            line-height: 19px;
            text-align: justify;
            letter-spacing: 0px;
            color: #8799B0;
        }

        .ant-menu-title-content {
            font-family: PingFangSC-Regular;
            font-size: 18px;
            letter-spacing: 0;
            font-weight: 400;
        }

        .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
            background-color: #edf4ff !important;
            color: #318bdd;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0;
            border-radius: 8px
        }

        .ant-menu-submenu-selected>.ant-menu-submenu-title {
            background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
            border-radius: 8px;
            color: white;
        }

        .ant-menu-item-selected {
            background-color: rgb(4, 77, 92) !important;
        }

        .ant-menu.ant-menu-dark {
            padding: 0 20px !important;
        }

        .ant-menu-submenu-open{
            color: #8799B0;
        }

        .ant-menu-item-active{
            color: #2077FF !important;
        }

        .ant-menu-submenu-title:hover {
            color: #2077FF;
          }
    }
}