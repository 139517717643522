.recommendedCourse {

    .ant-select-selector{
        padding: 0 !important;
    }

    header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        font-family: PingFangSC-Semibold;
        font-size: 18px;
        color: #1F78FE;
        letter-spacing: 0.66px;
        font-weight: 600;
        border-bottom: 1px solid #F6F6F6;

        .p {
            font-family: PingFangSC-Regular;
            font-size: 18px;
            color: #3E454D;
            letter-spacing: 0;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 16.5px 0;

            .text{
                width: 90px;
                text-align: right;
            }

            .ant-input-affix-wrapper {
                width: 18rem;
                height: 3rem;
                border-radius: 24px;
                // margin-right: 7.125rem;
            }

            .ant-select {
                width: 18rem;
                // margin-right: 7.125rem;
            }

            .ant-select-selector {
                height: 48px;
                background: #FFFFFF;
                border: 1px solid rgba(214, 221, 228, 1);
                border-radius: 24px;
                padding: 0 11px !important;

                .ant-select-selection-item {
                    line-height: 48px;
                    font-size: 16px;
                    color: #868EBE;
                }
            }

            .ant-btn {
                width: 7.375rem;
                height: 2.875rem;
                padding: 6.4px 20px;
                font-size: 18px;
                border-radius: 24px;
                background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
            }

            .ant-btn-round {
                margin-right: 1.25rem;
            }
        }
        // .p:nth-child(7){
        //     width: 89%;
        //     justify-content: right;
        // }
    }

    .tongJi {
        height: 5.5625rem;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #3E454D;
        letter-spacing: 0;
        text-align: right;
        line-height: 28px;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        border-bottom: 1px solid #F6F6F6;

        span {
            font-family: PingFangSC-Regular;
            font-size: 20px;
            color: #2077FF;
            letter-spacing: 0;
            text-align: right;
            line-height: 28px;
            font-weight: 400;
        }
    }

    .table {
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #3E454D;
        letter-spacing: 0;
        text-align: justify;
        font-weight: 500;
    }

    footer {
        padding: 60px 0;
        text-align: right;
    }
}

.ant-modal {
    width: 630px !important;
}

.allot {

    header {
        width: 100%;
        font-family: Source Han Sans;
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 0px;
        color: #3E454D;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 20px;

        p {
            width: 4px;
            height: 22px;
            margin: 0;
            margin-right: 10px;
            background-image: linear-gradient(180deg, #19A3F6 0%, #2077FF 84%);
            border-radius: 2px;
        }
    }

    .select {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }

    .ant-pagination {
        margin-top: 20px;
        text-align: center;
    }

    .ant-form-item-label {
        flex-grow: 0;
        width: 80px;
    }

    .ant-form-item-control-input-content {
        justify-content: left;
    }

    .ant-radio-wrapper-in-form-item {
        width: 117px;
    }
}

.ant-select-dropdown {
    width: 255px !important;

    .p1 {
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #1F78FF;
        letter-spacing: 0;
        font-weight: 500;
        display: flex;
        align-items: center;
    }

    .p2 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 4px;
        width: 250px;
        height: 37px;
        padding: 0 12px;

        span {
            display: flex;
            justify-content: center;
            width: 32%;
            font-family: PingFangSC-Medium;
            font-size: 14px;
            color: #3A4064;
            letter-spacing: 0;
            font-weight: 500;
        }

        .span1 {
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #96A2B4;
            letter-spacing: 0;
            font-weight: 400;
        }

        .span2 {
            font-family: PingFangSC-Regular;
            font-size: 14px;
            color: #3A4064;
            letter-spacing: 0;
            font-weight: 400;
        }
    }
}