.login-container {
	display: flex;
	align-items: center;
	width: 1920px;
	height: 1080px;
	background-size: 100% 100%;
	position: relative;

	img {
		position: absolute;
		top: 142px;
		left: 215px;
		width: 225.38px;
		height: 74px;
	}

	.logins {
		width: 580px;
		min-height: 400px;
		max-height: 774px;
		background-color: red;
		background: #FFFFFF;
		box-shadow: 0px 8px 35px 6px rgba(22, 48, 102, 0.08);
		border-radius: 28px;
		position: absolute;
		right: 14%;
	}

	h1 {
		text-align: center;
		margin: 34px 0 43px 0;
		font-family: PingFangSC-Semibold;
		font-size: 28px;
		color: #4E5C68;
		letter-spacing: 0;
		font-weight: 600;

		p {
			margin: 0;
			font-family: PingFangSC-Semibold;
			font-size: 18px;
			color: #B1BFCC;
			letter-spacing: 0;
			font-weight: 600;
		}
	}

	.ant-form-item {
		display: flex;
		justify-content: center;
	}

	header {
		height: 40px;
		text-align: center;
		font-family: PingFangSC-Semibold;
		font-size: 28px;
		color: #4E5C68;
		letter-spacing: 0;
		font-weight: 600;
		margin: 15px 0;
	}

	footer {
		text-align: center;
		margin-bottom: 30px;

		span {
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #2078FF;
			line-height: 20px;
			font-weight: 400;
		}
	}

	.Tabs {
		// filter: drop-shadow(rgba(0, 0, 0, 0.15) 0 3px 6px);
		border-radius: 10.6px;
		transition: unset;

		.ant-tabs-tab {
			font-family: PingFangSC-Semibold;
			font-size: 28px;
			color: #1F78FF;
			letter-spacing: 0;
			font-weight: 600;
		}

		.ant-form-item-margin-offset {
			margin-bottom: 0 !important;
		}

		// .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
		// .ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar {
		// 	height: 5px;
		// 	width: 100px;
		// 	background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
		// 	box-shadow: 0 4px 16px 0 rgba(2, 84, 207, 0.20);
		// 	border-radius: 3px;
		// 	top: 1.8125rem;
		// 	left: 0;
		// }

		.ant-row {
			display: flex;
			flex-flow: row wrap;
			width: 460px;
			height: 58px;
			background: #F6F9FF;
			border-radius: 28px;
			align-items: center;
			padding: 0 14px;
		}

		.ant-input {
			border: none;
			background: none;
			font-size: 18px !important;
		}

		.ant-input-affix-wrapper {
			border: none;
			background: none
		}

		.ant-form label {
			font-size: 0px !important;
		}

		.ant-space-item {
			.ant-btn {
				font-size: 18px;
			}
		}

		.ant-select-selection-item {
			text-align: left;
			font-size: 18px;
		}

		.name {

			.ant-row {
				background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
				box-shadow: 0 4px 16px 0 rgba(2, 84, 207, 0.20);
				border-radius: 29px;
			}

			.ant-btn {
				width: 25.3125rem;
				height: 3.625rem;
				background: none;
				border: none;
				font-family: PingFangSC-Medium;
				font-size: 20px;
				color: #FFFFFF;
				font-weight: 500;
			}
		}
	}

	.anniu-fasong-yzms {
		position: absolute;
		right: 26%;
		bottom: 66%;
		width: 90px;
		height: 25px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #1F78FF;
		font-weight: 500;
	}

	.ant-space {
		gap: 65px !important;
	}

	.wangJi {
		margin: 0 auto;
		width: 28.75rem;
		height: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #666666;
		font-weight: 400;
	}

	.ant-form-item-control-input-content {
		flex: inherit !important;
		max-width: 100%;
	}

	.ant-form-item-control-input-content {
		width: 100%;
	}

	.ant-select-selector {
		border: none !important;
		background: none !important;
	}

	.ant-select-selection-placeholder {
		text-align: left;
		font-size: 18px;
	}

	.ant-tabs-content-holder {
		padding-top: 10px;
	}

	.ant-form-item-control {
		margin: 0 auto;
		text-align: center;
	}

	.xieYi {
		display: flex;
		padding-left: 72px;
		align-items: center;
		font-size: 17px;

		p {
			margin: 0;
			margin-left: 1%;
			font-family: PingFangSC-Regular;
			font-size: 14px;
			color: #666666;
			font-weight: 400;

			span {
				color: #19A3F6;
			}
		}
	}

}

.dataScreen {
	width: 1920px;
	height: 1080px;
	transform-origin: 0 0;
	position: absolute;
	left: 50%;
	top: 50%;
	transition: 0.3s;
}

.ant-layout-sider {
	margin-right: 34px !important;
	width: 312px !important;
	background: #FFFFFF !important;
	box-shadow: 8px 0px 20px 0px rgba(227, 227, 227, 0.5) !important;
	border-radius: 16px !important;
	flex: 0 0 312px;
	max-width: 312px;
	min-width: 312px;

	.anticon svg {
		display: inline-block;
		color: #7D91AA;
	}
}

.ant-layout.ant-layout-has-sider>.ant-layout {
	background: #FFFFFF !important;
	box-shadow: 8px 0px 20px 0px rgba(227, 227, 227, 0.5) !important;
	border-radius: 16px !important;
	padding: 40px;
	overflow: auto;
}

.ant-tabs-nav {
	margin-top: 68px !important;
}

.register {
	.ant-tabs-nav-wrap {
		justify-content: center;

		.ant-tabs-tab {
			font-family: PingFangSC-Regular;
			font-size: 22px;
			color: #4E5C68;
			letter-spacing: 0;
			font-weight: 400;
		}

		.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
			font-family: PingFangSC-Semibold;
			font-size: 24px;
			color: #1F78FF;
			letter-spacing: 0;
			font-weight: 600;
		}

		.ant-tabs-ink-bar {
			background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
			border-radius: 3px;
			height: 6px !important;
		}

		.ant-tabs-nav-wrap {
			position: unset !important;
		}
	}
}