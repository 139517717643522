.reviewWorks {
    header {
        width: 100%;
        font-family: PingFangSC-Medium;
        font-size: 24px;
        color: #3E454D;
        letter-spacing: 0;
        text-align: justify;
        font-weight: 500;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p {
            width: 4px;
            height: 22px;
            margin-right: 10px;
            background-image: linear-gradient(180deg, #19A3F6 0%, #2077FF 84%);
            border-radius: 2px;
        }
    }

    .ant-form-item-control-input-content {
        display: flex;
        justify-content: space-between;
    }

    .ant-input-disabled {
        border-radius: 8px !important;
    }

    .ant-select-selector {
        height: 58px !important;
        background: #F6F9FF;
        border-radius: 8px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #666666;
        font-weight: 400;

        .ant-select-selection-item {
            text-align: initial;
        }
    }

    .ant-form-item-label {
        font-family: PingFangSC-Regular;
        font-size: 18px !important;
        color: #3E454D;
        letter-spacing: 0;
        text-align: right;
        font-weight: 400;
        height: 58px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .ant-form-item .ant-form-item-label>label {
        font-family: PingFangSC-Regular;
        font-size: 18px !important;
        color: #3E454D;
        letter-spacing: 0;
    }

    .ant-input {
        height: 58px !important;
        font-family: PingFangSC-Regular;
        font-size: 18px !important;
        color: #999999;
        font-weight: 400;
    }

    .ant-select-single {
        height: 58px !important;
    }

    .ant-form-item-control {
        justify-content: center !important;
    }

    .ant-form-item {
        max-width: none !important;
    }

    .video {
        width: 440px;
        height: 239px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        span {
            width: 90% !important;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
            white-space: nowrap;
        }
    }

    .video:nth-child(1) {
        margin-right: 80px;
    }

    .fujian {
        padding-top: 20px;

        p {
            margin-bottom: 16px;

            a {
                TEXT-DECORATION: none;
                font-family: PingFangSC-Regular;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.88);
                line-height: 22px;
                font-weight: 400;
            }

            .icon {
                opacity: 0.88;
                color: #1F7AFE;
            }
        }
    }

    .ant-input-number-input {
        background: #FFFFFF;
        border: 1px solid rgba(219, 219, 219, 1);
        border-radius: 8px;
        height: 58px;
        width: 600px;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #999999;
        font-weight: 400;
    }

    .ant-input-number {
        border: none;
    }

    .queDing {
        width: 142px;
        height: 48px;
        background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
        border-radius: 27.5px;
        margin: 100px 0 50px;
    }

    .quXiao {
        width: 142px;
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #1F79FF;
        border-radius: 27.5px;
        // margin: 100px 0 50px;
        font-family: PingFangSC-Regular;
        font-size: 18px;
        color: #1F79FF;
        letter-spacing: 0.66px;
        font-weight: 400;
    }
}