.entrepreneurXinJian {
    header {
        width: 100%;
        font-family: PingFangSC-Medium;
        font-size: 24px;
        color: #3E454D;
        letter-spacing: 0;
        text-align: justify;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 50px;

        p {
            width: 4px;
            height: 22px;
            margin-right: 10px;
            background-image: linear-gradient(180deg, #19A3F6 0%, #2077FF 84%);
            border-radius: 2px;
        }
    }

    .ant-form {

        .ant-input {
            height: 58px;
            background: #FFFFFF;
            border: 1px solid rgba(219, 219, 219, 1);
            border-radius: 8px;
        }

        .ant-form-item-label {
            line-height: 58px;
            margin-right: 1%;
        }

        .ant-form-item .ant-form-item-label>label {
            line-height: 58px;
            font-family: PingFangSC-Regular;
            font-size: 18px;
            color: #3E454D;
            letter-spacing: 0;
            text-align: right;
            font-weight: 400;
        }

        .ant-form-item-control-input-content Button:nth-child(1) {
            width: 92px !important;
            height: 46px;
            background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
            border-radius: 27.5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: PingFangSC-Semibold;
            font-size: 18px;
            color: #FFFFFF;
            letter-spacing: 0.66px;
            font-weight: 600;
            margin-right: 12px;
        }

        .ant-form-item-control-input-content Button:nth-child(2) {
            width: 92px !important;
            height: 46px;
            background: #FFFFFF;
            border: 1px solid #1F79FF;
            border-radius: 27.5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: PingFangSC-Regular;
            font-size: 18px;
            color: #1F79FF;
            letter-spacing: 0.66px;
            font-weight: 400;
        }

        .ant-select.ant-select-in-form-item {
            height: 58px;
        }
    }

    .ant-input {
        height: 58px !important;
        font-family: PingFangSC-Regular;
        font-size: 18px !important;
        color: #999999;
        font-weight: 400;
    }

    .ant-select-selector {
        height: 58px !important;
    }

    .ant-select-selection-item {
        text-align: left;
        line-height: 58px !important;
        font-size: 17px;
    }

    .ant-select-selection-placeholder {
        height: 58px !important;
        line-height: 58px !important;
        font-size: 18px;
    }

    .ant-form-item-control-input-content {
        display: flex;
        justify-content: left;
    }

    .ant-form-item-control {
        justify-content: center !important;
    }
}

.entrepreneur {
    .ant-input-group-wrapper {
        width: 300px !important;
    }

    .ant-input-search-button {
        margin-left: 12px;
        background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
        border-radius: 24px !important;
    }

    .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
        background: #FFFFFF;
        border: 1px solid rgba(214, 221, 228, 1);
        border-radius: 24px;
    }

    .ant-input-affix-wrapper {
        border-radius: 25px !important;
    }

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: PingFangSC-Semibold;
        font-size: 18px;
        color: #1F78FE;
        letter-spacing: 0.66px;
        font-weight: 600;
        margin-bottom: 46px;

        .headerButton {
            Button:nth-child(2) {
                margin: 0 20px;
                color: blue;
            }
        }

        .p {
            font-family: PingFangSC-Regular;
            font-size: 18px;
            color: #3E454D;
            letter-spacing: 0;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 29.5px 0;

            .ant-input-affix-wrapper {
                width: 19.5rem;
                height: 3rem;
                border-radius: 24px;
                margin-right: 2.5rem;
            }

            .ant-select {
                margin-right: 10.125rem;
                width: 310px;
            }

            .ant-select-selector {
                height: 48px;
                background: #FFFFFF;
                border: 1px solid rgba(214, 221, 228, 1);
                border-radius: 24px;

                .ant-select-selection-item {
                    line-height: 48px;
                    font-size: 16px;
                    color: #868EBE;
                }
            }
        }

    }

    .table {
        font-size: 17px;
    }

    .ant-input-group-addon {
        background: none;
    }

    .ant-pagination {
        text-align: center;
    }

    footer {
        margin-top: 20px;
    }
}