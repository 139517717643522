.shortlistedCourse{
    header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: right;
        font-family: PingFangSC-Semibold;
        font-size: 18px;
        color: #1F78FE;
        letter-spacing: 0.66px;
        font-weight: 600;
        border-bottom: 1px solid #F6F6F6;

        .p {
            font-family: PingFangSC-Regular;
            font-size: 18px;
            color: #3E454D;
            letter-spacing: 0;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 29px 5rem 29px 0;

            .ant-input-affix-wrapper {
                width: 18rem;
                height: 3rem;
                border-radius: 24px;
                // margin-right: 5rem;
            }

            .ant-select {
                width: 18rem;
                // margin-right: 5rem;
            }

            .ant-select-selector {
                height: 48px;
                background: #FFFFFF;
                border: 1px solid rgba(214, 221, 228, 1);
                border-radius: 24px;

                .ant-select-selection-item {
                    line-height: 48px;
                    font-size: 16px;
                    color: #868EBE;
                }
            }

            .ant-btn {
                width: 7.375rem;
                height: 2.875rem;
                padding: 6.4px 20px;
                font-size: 18px;
                border-radius: 24px;
                background-image: linear-gradient(90deg, #19A3F6 1%, #2077FF 100%);
            }

            .ant-btn-round {
                margin-right: 1.25rem;
            }
        }
    }

    .tongJi {
        height: 5.5625rem;
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #3E454D;
        letter-spacing: 0;
        text-align: right;
        line-height: 28px;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        border-bottom: 1px solid #F6F6F6;

        span {
            font-family: PingFangSC-Regular;
            font-size: 20px;
            color: #2077FF;
            letter-spacing: 0;
            text-align: right;
            line-height: 28px;
            font-weight: 400;
        }
    }

    .table {
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #3E454D;
        letter-spacing: 0;
        text-align: justify;
        font-weight: 500;
    }

    footer {
        padding: 60px 0;
        text-align: right;
    }
}